import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { UilPlus, UilTimesCircle } from "@iconscout/react-unicons";
import { convertToEur, isNull, roundToTwo } from "../../utils/Utils";
import UnitEnum from "../../enum/UnitEnum";
import InvoiceItemsTotal from "./InvoiceItemsTotal";

const InvoiceItems = (props) => {
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState();
  const [currency, setCurrency] = useState();
  const [quantity, setQuantity] = useState(1);
  const [tax, setTax] = useState();
  const [total, setTotal] = useState();
  const [priceLcy, setPriceLcy] = useState();
  const [totalLcy, setTotalLcy] = useState();
  const [priceHrk, setPriceHrk] = useState();
  const [totalHrk, setTotalHrk] = useState();
  const [taxLcy, setTaxLcy] = useState();
  const [taxHrk, setTaxHrk] = useState();

  const items = props.items;
  const companyInfo = JSON.parse(sessionStorage.getItem("companyInfo"));

  const clearStates = () => {
    setSelectedItem(null);
    setDescription(null);
    setPrice(null);
    setCurrency(null);
    setQuantity(null);
    setPriceLcy(null);
    setTotal(null);
    setTotalLcy(null);
    setPriceHrk(null);
    setTotalHrk(null);
    setTaxLcy(null);
    setTaxHrk(null);
    setTax(null);
  };

  const handleItemSelect = (event, value) => {
    clearStates();
    const item = value;
    setSelectedItem(item);
    setTax(item.tax);
  };

  useEffect(() => {
    if (!isNull(selectedItem)) {
      setPrice(isNull(price) ? selectedItem.price : price);
      setCurrency(selectedItem.currency);
      setDescription(description);
      setQuantity(isNull(quantity) ? 1 : quantity);
      setTax(selectedItem.tax);
      setPriceHrk(isNull(price) ? convertToEur(selectedItem.price) : convertToEur(price));
      setPriceLcy(isNull(price) ? selectedItem.price : price);
      if (!isNull(price) && !isNull(quantity)) {
        setTotal(+price * +quantity);
        setTotalLcy(+price * +quantity);
        setTotalHrk(convertToEur(+price * +quantity));
      }
    } else {
      clearStates();
    }
  }, [selectedItem, price, quantity]);

  useEffect(() => {
    if (!isNull(totalLcy) && !isNull(tax)) {
      setTaxLcy(roundToTwo(totalLcy * (tax.taxRate / 100)));
    } else {
      setTaxLcy(0);
    }
    if (!isNull(totalHrk) && !isNull(tax)) {
      setTaxHrk(roundToTwo(totalHrk * (tax.taxRate / 100)));
    } else {
      setTaxHrk(0);
    }
  }, [totalLcy, totalHrk, tax])

  useEffect(() => {
    setInvoiceItems(props.invoiceItems)
  }, [props.invoiceItems])

  const addItem = () => {
    const newItem = {
      itemName: selectedItem.name,
      description: description,
      quantity: quantity,
      unit: selectedItem.unit,
      price: price,
      priceLocalCurrency: priceLcy,
      priceHrk: priceHrk,
      currency: currency,
      totalItemCurrency: total,
      totalLocalCurrency: totalLcy,
      totalHrk: totalHrk,
      taxItemCurrency: taxLcy,
      taxLocalCurrency: taxLcy,
      taxHrk: taxHrk,
      discount: 0,
      isDiscountPercentage: false,
      tax: tax,
    };
    props.handleItemAdded([...invoiceItems, newItem]);
    setInvoiceItems([...invoiceItems, newItem]);
    clearStates();
  };


  const removeItem = (index) => {
    let items = [...invoiceItems];
    if (index > -1) {
      items.splice(index, 1); // 2nd parameter means remove one item only
    }
    setInvoiceItems((prev) => {
      props.handleItemAdded([...items]);
      return [...items];
    });
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
      >
        <Table stickyHeader sx={{ minWidth: 1200 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Items</TableCell>
              <TableCell align="left">Price</TableCell>
              <TableCell align="left">Currency</TableCell>
              <TableCell align="left">Quantity</TableCell>
              <TableCell align="left">Tax</TableCell>
              <TableCell align="left">Total</TableCell>
              <TableCell align="left">Price ({companyInfo.localCurrency})</TableCell>
              <TableCell align="left">Total ({companyInfo.localCurrency})</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ color: "white" }}>
            {invoiceItems &&
              invoiceItems.map((row, index) => (
                <TableRow
                  hover
                  key={index}
                >
                  <TableCell component="th" scope="row" align="left">
                    <Typography variant="body1">{row.itemName}</Typography>
                    <Typography variant="caption">{row.description}</Typography>
                  </TableCell>
                  <TableCell align="left">{roundToTwo(row.price)}</TableCell>
                  <TableCell align="left">{row.currency}</TableCell>
                  <TableCell align="left">{roundToTwo(row.quantity) + " " + UnitEnum[row.unit]}</TableCell>
                  <TableCell align="left">{isNull(row.tax) ? "0%" : (roundToTwo(row.tax.taxRate) + "%")}</TableCell>
                  <TableCell align="center">{roundToTwo(row.totalItemCurrency)}</TableCell>
                  <TableCell align="center">{roundToTwo(row.priceLocalCurrency)}</TableCell>
                  <TableCell align="center">{roundToTwo(row.totalLocalCurrency)}</TableCell>
                  <TableCell align="center" className="Details">
                    <Button variant="compact" onClick={() => removeItem(index)}>
                      <UilTimesCircle />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            <TableRow
              hover
              key={"new"}
            >
              <TableCell component="th" scope="row">
              
              <Autocomplete
                    disabled={props.isReadOnly}
                    disablePortal
                    sx={{width: 250 }}
                    options={items}
                    getOptionLabel={(item) => item.name}
                    onChange={handleItemSelect}
                    value={selectedItem || null}
                    renderInput={(params) => <TextField {...params} label="Item" variant="outlined" />}
                  />
                  <TextField
                    sx={{ width: 250 }}
                    value={isNull(description) ? "" : description}
                    type="text"
                    placeholder="Description"
                    onChange={(e) => setDescription(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
              </TableCell>
              <TableCell align="left">
                <TextField
                  value={isNull(price) ? "" : price}
                  type="number"
                  onChange={(e) => setPrice(roundToTwo(e.target.value))}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </TableCell>
              <TableCell align="left">
                {isNull(currency) ? "" : currency}
              </TableCell>
              <TableCell align="left">
                <TextField
                  value={isNull(quantity) ? "" : quantity}
                  type="number"
                  onChange={(e) => setQuantity(roundToTwo(e.target.value))}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {selectedItem && UnitEnum[selectedItem.unit]}
                      </InputAdornment>
                    ),
                  }}
                />
              </TableCell>
              <TableCell align="left">
                {isNull(tax) ? "0%" : (roundToTwo(tax.taxRate) + "%")}
              </TableCell>
              <TableCell align="center">
                {isNull(total) ? "" : roundToTwo(total)}
              </TableCell>
              <TableCell align="center">
                {isNull(priceLcy) ? "" : roundToTwo(priceLcy)}
              </TableCell>
              <TableCell align="center">
                {isNull(totalLcy) ? "" : roundToTwo(totalLcy)}
              </TableCell>
              <TableCell align="left" className="Details">
                <Button variant="compact" onClick={addItem}>
                  <UilPlus />
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <InvoiceItemsTotal items={invoiceItems} handleCaluclationsChange={props.handleCaluclationsChange} />
    </>
  );
};

export default InvoiceItems;
