export const APP_VERSION = "V2.5.0";

export const DELETE_RECORD_TITLE = "Delete record?";
export const DELETE_RECORD_MESSAGE = "Are you sure you want to delete this record?"

export const getSendInvoiceTitle = (invoiceNumber) => {
    return "Sending invoice no. " + invoiceNumber
}

export const getSendInvoiceBody = (invoiceNumber, email) => {
    return "Do you want to send invoice no. " + invoiceNumber + " to email address " + email
}
