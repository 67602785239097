import { Button, Chip, IconButton, Toolbar, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { GetPostRequestAndDownload, SendGetRequest, SendPutRequest } from "../../actions/Actions";
import InvoiceBarcode from "../../components/Invoice/InvoiceBarcode";
import { baseUrl } from "../../Config";
import Color from "../../enum/Color";
import ConditionsENGEnum from "../../enum/ConditionsENGEnum";
import ConditionsEnum from "../../enum/ConditionsEnum";
import InvoiceStatusEnum from "../../enum/InvoiceStatusEnum";
import LanguageEnum from "../../enum/LanguageEnum";
import PaymentMethodENGEnum from "../../enum/PaymentMethodENGEnum";
import PaymentMethodEnum from "../../enum/PaymentMethodEnum";
import UnitENGEnum from "../../enum/UnitENGEnum";
import UnitEnum from "../../enum/UnitEnum";
import Logo from "../../imgs/logo.svg";
import AuthContext from "../../store/auth-context";
import useApiRequest from "../../store/use-http";
import { formatDate, formatPrice, isNull } from "../../utils/Utils";
import "./InvoicePreview.css";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import {
  UilEnvelopeUpload,
  UilFileDownload,
  UilMoneyBillStack,
  UilFileCheck
} from "@iconscout/react-unicons";
import ConfirmationModal from "../Modal/ConfirmationModal";
import { getSendInvoiceBody, getSendInvoiceTitle } from "../../Constants/Constants";

const statusStyle = (status) => {
  if (status === InvoiceStatusEnum.PAID) {
    return {
      backgroundColor: Color.SUCCESS,
      color: Color.FOREGROUND,
    };
  } else if (status === InvoiceStatusEnum.SENT) {
    return {
      backgroundColor: Color.WARNING,
      color: Color.FOREGROUND,
    };
  } else {
    return {
      backgroundColor: Color.BACKGROUND,
      color: Color.FOREGROUND,
    };
  }
};

const InvoicePreview = () => {
  const companyInfo = JSON.parse(sessionStorage.getItem("companyInfo"));
  const params = useParams();

  const [showDialog, setShowDialog] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [status, setStatus] = useState(null);

  const authContext = useContext(AuthContext);

  const url = baseUrl + "/api/invoice?id=" + params.invoiceId;
  

  const { data } = useApiRequest(
    url,
    "GET",
    authContext.token
  );

  useEffect(() => {
    setInvoice(data);
    setStatus(data.status);
  }, [data]);

  const downloadPDFOld = () => {
    const options = {
      filename: !isNull(invoice) &&
        (LanguageEnum[invoice.customer.language] === LanguageEnum.ENG
          ? "Invoice "
          : "Faktura ") + invoice.invoiceNumber,
      method: "save",
      // default is Resolution.MEDIUM = 3, which should be enough, higher values
      // increases the image quality but also the size of the PDF, so be careful
      // using values higher than 10 when having multiple pages generated, it
      // might cause the page to crash or hang.
      resolution: Resolution.EXTREME,
      page: {
        // margin is in MM, default is Margin.NONE = 0
        margin: Margin.SMALL,
        // default is 'A4'
        format: "A4",
        // default is 'portrait'
        orientation: "portrait"
      },
      canvas: {
        // default is 'image/jpeg' for better size performance
        mimeType: "image/jpeg",
        qualityRatio: 1
      },
      // Customize any value passed to the jsPDF instance and html2canvas
      // function. You probably will not need this and things can break,
      // so use with caution.
      overrides: {
        // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
        pdf: {
          compress: false
        },
        // see https://html2canvas.hertzen.com/configuration for more options
        canvas: {
          useCORS: true
        }
      }
    };
    const getTargetElement = () => document.getElementById("invoice");
    generatePDF(getTargetElement, options);
  }

  const downloadPDF = () => {
    const downloadUrl = baseUrl + "/api/download/invoice/" + invoice.id;
    GetPostRequestAndDownload(downloadUrl, authContext.token)
  }

  const handleSentStatusChange = () => {
    const oldInvoice = invoice;
    if (!isNull(oldInvoice)) {
      oldInvoice.status = InvoiceStatusEnum.SENT;
    }
    setStatus(InvoiceStatusEnum.SENT);
    const url = baseUrl + "/api/invoice";
    SendPutRequest(url, oldInvoice, authContext.token);
  };

  const handlePaidStatusChange = () => {
    const oldInvoice = invoice;
    if (!isNull(oldInvoice)) {
      oldInvoice.status = InvoiceStatusEnum.PAID;
    }
    setStatus(InvoiceStatusEnum.PAID);
    const url = baseUrl + "/api/invoice";
    SendPutRequest(url, oldInvoice, authContext.token);
  };

  const toggleConfirmationDialog = () => {
    setShowDialog((prev) => {
      return !prev;
    });
  };

const handleAfterMailSend = () => {
  toggleConfirmationDialog();
  handleSentStatusChange();
}

  const sendInvoiceViaEmail = () => {
    const url = baseUrl + "/api/download/send/invoice/" + invoice.id;
    SendGetRequest(url, authContext.token, handleAfterMailSend);
  }

  return (
    <div style={{ backgroundColor: Color.ACCENT1 }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <ConfirmationModal
          isVisible={showDialog}
          confirmAction={sendInvoiceViaEmail}
          denieAction={toggleConfirmationDialog}
          title={invoice && getSendInvoiceTitle(invoice.invoiceNumber)}
          message={(invoice && invoice.customer) && getSendInvoiceBody(invoice.invoiceNumber, invoice.customer.email)}
        />
        <Toolbar
          style={{
            display: "flex",
            gap: "1rem",
            justifyContent: "center",
            backgroundColor: Color.ACCENT2,
          }}
        >
          <Tooltip
            title="Send invoice"
          >
            <Button
              disabled={!isNull(invoice) && (invoice.status == InvoiceStatusEnum.SENT || invoice.status == InvoiceStatusEnum.PAID)}
              variant="contained"
              onClick={toggleConfirmationDialog}
              aria-label="Send invoice"
            >
              <UilEnvelopeUpload />
            </Button>
          </Tooltip>

          <Tooltip
            title="Download invoice"
          >
            <Button
              variant="contained"
              style={{ backgroundColor: Color.BACKGROUND }}
              onClick={downloadPDF}
            >
              <UilFileDownload />
            </Button>
          </Tooltip>

          <Tooltip
            title="Mark as sent"
          >
            <Button
              disabled={!isNull(invoice) && (invoice.status == InvoiceStatusEnum.SENT || invoice.status == InvoiceStatusEnum.PAID)}
              variant="contained"
              onClick={handleSentStatusChange}
              style={{ backgroundColor: Color.WARNING }}
            >
              <UilFileCheck />
            </Button>
          </Tooltip>

          <Tooltip
            title="Mark as paid"
          >
            <Button
              disabled={!isNull(invoice) && (invoice.status == InvoiceStatusEnum.DRAFT || invoice.status == InvoiceStatusEnum.PAID)}
              variant="contained"
              onClick={handlePaidStatusChange}
              style={{ backgroundColor: Color.SUCCESS }}
            >
              <UilMoneyBillStack />
            </Button>
          </Tooltip>
          <span>
            Invoice status:{" "}
            <Chip
              sx={{ width: "80px" }}
              label={!isNull(invoice) && invoice.status}
              style={statusStyle(!isNull(invoice) && invoice.status)}
            />
          </span>
        </Toolbar>
      </div>
      <div className="invoicePage" id="invoice">
        <div
          className="details-container clearfix invoicePage"
          style={{ margiLeft: "auto", margiRight: "auto" }}
        >
          <div className="pcs-template flex">
            <div>
              <div className="pcs-template-body">
                <table
                  className="invoiceTable"
                  style={{ width: "100%", tableLayout: "fixed" }}
                >
                  <tbody>
                    <tr>
                      <td style={{ verticalAlign: "top", width: "50%" }}>
                        <div>
                          <img
                            src={Logo}
                            alt="Logo"
                            id="logo_content"
                          />
                        </div>
                        <span className="pcs-orgname">
                          <b>{companyInfo.companyName}</b>
                        </span>
                        <br />
                        <span className="pcs-label">
                          <span id="tmp_org_address" className="preWrap">
                            {companyInfo.address}
                          </span>
                          <br />
                          <span>
                            {companyInfo.zipCode}, {companyInfo.city}
                          </span>{" "}
                          <br />
                          <span>{companyInfo.country}</span> <br />
                          <span>OIB: {companyInfo.businessId}</span> <br />
                          <span>IBAN: {companyInfo.iban}</span> <br />

                          {!isNull(invoice) && LanguageEnum[invoice.customer.language] ===
                            LanguageEnum.ENG &&
                            <span>SWIFT: {companyInfo.swift}</span>
                          }
                          {!isNull(invoice) && LanguageEnum[invoice.customer.language] ===
                            LanguageEnum.ENG &&
                            <br />
                          }

                          <span>
                            {!isNull(invoice) &&
                              (LanguageEnum[invoice.customer.language] ===
                                LanguageEnum.ENG
                                ? "VAT ID: "
                                : "PDV ID: ")}{" "}
                            {companyInfo.pdvId}
                          </span>
                        </span>
                      </td>
                      <td
                        style={{ width: "50%" }}
                        className="text-align-right v-top"
                      >
                        <span className="pcs-entity-title">
                          {!isNull(invoice) &&
                            (LanguageEnum[invoice.customer.language] ===
                              LanguageEnum.ENG
                              ? "INVOICE "
                              : "FAKTURA ")}
                        </span>
                        <br />
                        <span
                          id="tmp_entity_number"
                          style={{ fontSize: "10pt" }}
                          className="pcs-label"
                        >
                          <b>
                            {!isNull(invoice) &&
                              (LanguageEnum[invoice.customer.language] ===
                                LanguageEnum.ENG
                                ? "Invoice no. "
                                : "Br. fakture ")}{" "}
                            {invoice && invoice.invoiceNumber}
                          </b>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table
                  className="invoiceTable"
                  style={{
                    clear: "both",
                    width: "100%",
                    marginTop: "30px",
                    tableLayout: "fixed",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          width: "60%",
                          verticalAlign: "bottom",
                          wordWrap: "break-word",
                        }}
                      >
                        <div className="billto-section">
                          <label
                            style={{ fontSize: "10pt" }}
                            className="pcs-label"
                            id="tmp_billing_address_label"
                          >
                            {!isNull(invoice) &&
                              (LanguageEnum[invoice.customer.language] ===
                                LanguageEnum.ENG
                                ? "Customer "
                                : "Naručitelj ")}
                          </label>
                          <br />
                          <span className="preWrap" id="tmp_billing_address">
                            <strong>
                              <span
                                className="pcs-customer-name"
                                id="zb-pdf-customer-detail"
                              >
                                {invoice &&
                                  invoice.customer &&
                                  invoice.customer.companyDisplayName}
                              </span>
                            </strong>
                            <br />
                            <span>
                              {invoice &&
                                invoice.customer &&
                                invoice.customer.address}
                            </span>
                            <br />
                            <span>
                              {invoice &&
                                invoice.customer &&
                                invoice.customer.zipCode +
                                ", " +
                                invoice.customer.city}
                            </span>
                            <br />
                            <span>
                              {invoice &&
                                invoice.customer &&
                                invoice.customer.country}
                            </span>
                            <br />
                            <span>
                              OIB:{" "}
                              {invoice &&
                                invoice.customer &&
                                invoice.customer.businessId}
                            </span>
                            <br />
                            {invoice &&
                              invoice.customer &&
                              invoice.customer.pdvId &&
                              (LanguageEnum[invoice.customer.language] ===
                                LanguageEnum.ENG
                                ? "VAT ID: "
                                : "PDV ID: ") + invoice.customer.pdvId}
                          </span>
                        </div>
                      </td>
                      <td
                        align="right"
                        style={{ verticalAlign: "bottom", width: "40%" }}
                      >
                        <table
                          className="invoiceTable"
                          style={{
                            float: "right",
                            width: "100%",
                            tableLayout: "fixed",
                            wordWrap: "break-word",
                          }}
                          border="0"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  padding: "5px 10px 5px 0px",
                                  fontSize: "10pt",
                                }}
                                className="text-align-right"
                              >
                                <span className="pcs-label">
                                  {!isNull(invoice) &&
                                    (LanguageEnum[invoice.customer.language] ===
                                      LanguageEnum.ENG
                                      ? "Invoicing date: "
                                      : "Datum izdavanja: ")}
                                </span>
                              </td>
                              <td className="text-align-right">
                                <span id="tmp_entity_date">
                                  {!isNull(invoice) &&
                                    formatDate(invoice.invoiceDate)}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  padding: "5px 10px 5px 0px",
                                  fontSize: "10pt",
                                }}
                                className="text-align-right"
                              >
                                <span className="pcs-label">
                                  {!isNull(invoice) &&
                                    (LanguageEnum[invoice.customer.language] ===
                                      LanguageEnum.ENG
                                      ? "Conditions: "
                                      : "Uvjeti: ")}
                                </span>
                              </td>
                              <td className="text-align-right">
                                <span id="tmp_payment_terms">
                                  {!isNull(invoice) &&
                                    (LanguageEnum[invoice.customer.language] ===
                                      LanguageEnum.ENG
                                      ? ConditionsENGEnum[invoice.conditions]
                                      : ConditionsEnum[invoice.conditions])}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  padding: "5px 10px 5px 0px",
                                  fontSize: "10pt",
                                }}
                                className="text-align-right"
                              >
                                <span className="pcs-label">
                                  {!isNull(invoice) &&
                                    (LanguageEnum[invoice.customer.language] ===
                                      LanguageEnum.ENG
                                      ? "Due date: "
                                      : "Datum dospijeća: ")}
                                </span>
                              </td>
                              <td className="text-align-right">
                                <span id="tmp_due_date">
                                  {!isNull(invoice) &&
                                    formatDate(invoice.dueDate)}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  padding: "5px 10px 5px 0px",
                                  fontSize: "10pt",
                                }}
                                className="text-align-right"
                              >
                                <span className="pcs-label">
                                  {!isNull(invoice) &&
                                    (LanguageEnum[invoice.customer.language] ===
                                      LanguageEnum.ENG
                                      ? "Seller: "
                                      : "Fakturu izdao: ")}
                                </span>
                              </td>
                              <td className="text-align-right">
                                <span id="tmp_salesperson_name">
                                  {!isNull(invoice) && invoice.seller}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  padding: "5px 10px 5px 0px",
                                  fontSize: "10pt",
                                }}
                                className="text-align-right"
                              >
                                <span
                                  className="pcs-label"
                                  id="tmp_entity_cflabel"
                                >
                                  {!isNull(invoice) &&
                                    (LanguageEnum[invoice.customer.language] ===
                                      LanguageEnum.ENG
                                      ? "Delivery date: "
                                      : "Datum isporuke: ")}
                                </span>
                              </td>
                              <td className="text-align-right">
                                <span id="tmp_entity_cfvalue">
                                  {!isNull(invoice) &&
                                    formatDate(invoice.deliveryDate)}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  padding: "5px 10px 5px 0px",
                                  fontSize: "10pt",
                                }}
                                className="text-align-right"
                              >
                                <span
                                  className="pcs-label"
                                  id="tmp_entity_cflabel"
                                >
                                  {!isNull(invoice) &&
                                    (LanguageEnum[invoice.customer.language] ===
                                      LanguageEnum.ENG
                                      ? "Invoicing time: "
                                      : "Vrijeme izdavanja: ")}
                                </span>
                              </td>
                              <td className="text-align-right">
                                <span id="tmp_entity_cfvalue">
                                  {!isNull(invoice) && invoice.invoicingTime}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  padding: "5px 10px 5px 0px",
                                  fontSize: "10pt",
                                }}
                                className="text-align-right"
                              >
                                <span
                                  className="pcs-label"
                                  id="tmp_entity_cflabel"
                                >
                                  {!isNull(invoice) &&
                                    (LanguageEnum[invoice.customer.language] ===
                                      LanguageEnum.ENG
                                      ? "Payment method: "
                                      : "Način plaćanja: ")}
                                </span>
                              </td>
                              <td className="text-align-right">
                                <span id="tmp_entity_cfvalue">
                                  {!isNull(invoice) &&
                                    (LanguageEnum[invoice.customer.language] ===
                                      LanguageEnum.ENG
                                      ? PaymentMethodENGEnum[
                                      invoice.paymentMethod
                                      ]
                                      : PaymentMethodEnum[
                                      invoice.paymentMethod
                                      ])}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table
                  style={{
                    width: "100%",
                    marginTop: "20px",
                    tableLayout: "fixed",
                  }}
                  className="pcs-itemtable invoiceTable"
                  border="0"
                  cellSpacing="0"
                  cellPadding="0"
                >
                  <thead>
                    <tr style={{ height: "32px" }}>
                      <td
                        style={{
                          padding: "5px 0px 5px 5px",
                          width: "5%",
                          textAlign: "center",
                        }}
                        id=""
                        className="pcs-itemtable-header pcs-itemtable-breakword"
                      >
                        #
                      </td>
                      <td
                        style={{
                          padding: "5px 10px 5px 20px",
                          width: "",
                          textAlign: "left",
                        }}
                        id=""
                        className="pcs-itemtable-header pcs-itemtable-breakword"
                      >
                        {!isNull(invoice) &&
                          (LanguageEnum[invoice.customer.language] ===
                            LanguageEnum.ENG
                            ? "Item & description "
                            : "Stavka i opis ")}
                      </td>
                      <td
                        style={{
                          padding: "5px 10px 5px 5px",
                          width: "15%",
                          textAlign: "right",
                        }}
                        id=""
                        className="pcs-itemtable-header pcs-itemtable-breakword"
                      >
                        {!isNull(invoice) &&
                          (LanguageEnum[invoice.customer.language] ===
                            LanguageEnum.ENG
                            ? "Qty. "
                            : "Kol. ")}
                      </td>
                      <td
                        style={{
                          padding: "5px 10px 5px 5px",
                          width: "15%",
                          textAlign: "right",
                        }}
                        id=""
                        className="pcs-itemtable-header pcs-itemtable-breakword"
                      >
                        {!isNull(invoice) &&
                          (LanguageEnum[invoice.customer.language] ===
                            LanguageEnum.ENG
                            ? "Price "
                            : "Cijena ")}
                        (EUR)
                      </td>
                      {companyInfo.displayEuro === true && (
                        <td
                          style={{
                            padding: "5px 10px 5px 5px",
                            width: "10%",
                            textAlign: "right",
                          }}
                          id=""
                          className="pcs-itemtable-header pcs-itemtable-breakword"
                        >
                          {!isNull(invoice) &&
                            (LanguageEnum[invoice.customer.language] ===
                              LanguageEnum.ENG
                              ? "Price "
                              : "Cijena ")}
                          (HRK)
                        </td>
                      )}
                      <td
                        style={{
                          padding: "5px 10px 5px 5px",
                          width: "15%",
                          textAlign: "right",
                        }}
                        id=""
                        className="pcs-itemtable-header pcs-itemtable-breakword"
                      >
                        {!isNull(invoice) &&
                          (LanguageEnum[invoice.customer.language] ===
                            LanguageEnum.ENG
                            ? "Total "
                            : "Ukupno ")}{" "}
                        (EUR)
                      </td>
                      {companyInfo.displayEuro === true && (
                        <td
                          style={{
                            padding: "5px 10px 5px 5px",
                            width: "10%",
                            textAlign: "right",
                          }}
                          id=""
                          className="pcs-itemtable-header pcs-itemtable-breakword"
                        >
                          {!isNull(invoice) &&
                            (LanguageEnum[invoice.customer.language] ===
                              LanguageEnum.ENG
                              ? "Total "
                              : "Ukupno ")}{" "}
                          (HRK)
                        </td>
                      )}
                    </tr>
                  </thead>
                  <tbody className="itemBody">
                    {invoice &&
                      invoice.invoiceItems &&
                      invoice.invoiceItems.map((item, index) => {
                        return (
                          <tr className="breakrow-inside breakrow-after">
                            <td
                              rowSpan="1"
                              valign="top"
                              style={{
                                padding: "10px 0 10px 5px",
                                textAlign: "center",
                                wordWrap: "break-word",
                              }}
                              className="pcs-item-row"
                            >
                              {index + 1}
                            </td>
                            <td
                              rowSpan="1"
                              valign="top"
                              style={{ padding: "10px 0px 10px 20px" }}
                              className="pcs-item-row"
                            >
                              <div>
                                <div>
                                  <span
                                    style={{ wordWrap: "break-word" }}
                                    id="tmp_item_name"
                                  >
                                    {item.itemName}
                                  </span>
                                  <br />

                                  <span
                                    style={{
                                      whiteSpace: "pre-wrap",
                                      wordWrap: "break-word",
                                    }}
                                    className="pcs-item-desc"
                                    id="tmp_item_description"
                                  >
                                    {item.description}
                                  </span>
                                </div>
                              </div>
                            </td>

                            <td
                              rowSpan="1"
                              className="pcs-item-row lineitem-column text-align-right"
                              style={{
                                padding: "5px 10px 5px 5px",
                                width: "10%",
                                textAlign: "right",
                              }}
                            >
                              <span id="tmp_item_qty">
                                {formatPrice(item.quantity)}{" "}
                                {!isNull(invoice) &&
                                  (LanguageEnum[invoice.customer.language] ===
                                    LanguageEnum.ENG
                                    ? UnitENGEnum[item.unit]
                                    : UnitEnum[item.unit])}
                              </span>
                            </td>
                            <td
                              rowSpan="1"
                              className="pcs-item-row lineitem-column text-align-right"
                              style={{
                                padding: "5px 10px 5px 5px",
                                width: "10%",
                                textAlign: "right",
                              }}
                            >
                              <span id="tmp_item_rate">
                                {formatPrice(item.price)}
                              </span>
                            </td>
                            {companyInfo.displayEuro === true && (
                              <td
                                rowSpan="1"
                                className="pcs-item-row lineitem-column text-align-right"
                                style={{
                                  padding: "5px 10px 5px 5px",
                                  width: "10%",
                                  textAlign: "right",
                                }}
                              >
                                <span id="tmp_item_rate">
                                  {formatPrice(item.priceHrk)}
                                </span>
                              </td>
                            )}
                            <td
                              rowSpan="1"
                              className="pcs-item-row lineitem-column lineitem-content-right text-align-right"
                              style={{
                                padding: "5px 10px 5px 5px",
                                width: "10%",
                                textAlign: "right",
                              }}
                            >
                              <span id="tmp_item_amount">
                                {formatPrice(item.totalItemCurrency)}
                              </span>
                            </td>
                            {companyInfo.displayEuro === true && (
                              <td
                                rowSpan="1"
                                className="pcs-item-row lineitem-column lineitem-content-right text-align-right"
                                style={{
                                  padding: "5px 10px 5px 5px",
                                  width: "10%",
                                  textAlign: "right",
                                }}
                              >
                                <span id="tmp_item_amount">
                                  {formatPrice(item.totalHrk)}
                                </span>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div style={{ width: "50%", marginTop: "1px", float: "right" }}>
                  <div className="v-top total-number-section">
                    <div style={{ whiteSpace: "pre-wrap" }}></div>
                  </div>
                  <div className="v-top total-section">
                    <table
                      className="pcs-totals invoiceTable"
                      cellSpacing="0"
                      border="0"
                      width="100%"
                    >
                      <tbody>
                        <tr>
                          <td className="total-section-label text-align-right">
                            {!isNull(invoice) &&
                              (LanguageEnum[invoice.customer.language] ===
                                LanguageEnum.ENG
                                ? "Subtotal "
                                : "Vrijednost podzbroja ")}
                          </td>
                          <td
                            id="tmp_subtotal"
                            className="total-section-value text-align-right"
                          >
                            {!isNull(invoice) && formatPrice(invoice.subtotal)}{" "}
                            EUR
                            <br />
                            {companyInfo.displayEuro === true && (
                              <span>
                                {!isNull(invoice) &&
                                  formatPrice(invoice.subtotalHrk)}{" "}
                                HRK
                              </span>
                            )}
                          </td>
                        </tr>

                        {!isNull(invoice) && invoice.totalTax > 0 &&
                          <tr>
                            <td className="total-section-label text-align-right">
                              {!isNull(invoice) &&
                                (LanguageEnum[invoice.customer.language] ===
                                  LanguageEnum.ENG
                                  ? "VAT (25%) "
                                  : "PDV (25%) ")}
                            </td>
                            <td
                              id="tmp_subtotal"
                              className="total-section-value text-align-right"
                            >
                              {!isNull(invoice) && formatPrice(invoice.totalTax)}{" "}
                              EUR
                              <br />
                              {companyInfo.displayEuro === true && (
                                <span>
                                  {!isNull(invoice) &&
                                    formatPrice(invoice.totalTaxHrk)}{" "}
                                  HRK
                                </span>
                              )}
                            </td>
                          </tr>
                        }

                        <tr style={{ height: "40px" }} className="pcs-balance">
                          <td className="total-section-label text-align-right">
                            <b>
                              {!isNull(invoice) &&
                                (LanguageEnum[invoice.customer.language] ===
                                  LanguageEnum.ENG
                                  ? "Total "
                                  : "Ukupno ")}
                            </b>
                          </td>
                          <td
                            id="tmp_total"
                            className="total-section-value text-align-right"
                          >
                            <b>
                              {!isNull(invoice) && formatPrice(invoice.total)}{" "}
                              EUR
                              <br />
                              {companyInfo.displayEuro === true && (
                                <span>
                                  {!isNull(invoice) &&
                                    formatPrice(invoice.totalHrk)}{" "}
                                  HRK
                                </span>
                              )}
                            </b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div style={{ clear: "both" }}></div>
                </div>
                <tr style={{ height: "40px", width: "100%" }}>
                  <td
                    id="tmp_total"
                    className="total-section-value text-align-right"
                    style={{ width: "100%" }}
                  >
                    <b>{!isNull(invoice) &&
                      (LanguageEnum[invoice.customer.language] ===
                        LanguageEnum.ENG)
                      ? "Scan to pay code"
                      : "Kod za uplatu"}</b>
                    {!isNull(invoice) && <InvoiceBarcode invoice={invoice} />}
                  </td>
                </tr>
                <div
                  style={{ clear: "both", marginTop: "50px", width: "100%" }}
                >
                  <label
                    style={{ fontSize: "10pt" }}
                    id="tmp_notes_label"
                    className="pcs-label"
                  >
                    {!isNull(invoice) &&
                      (LanguageEnum[invoice.customer.language] ===
                        LanguageEnum.ENG
                        ? "Note "
                        : "Napomene ")}
                  </label>
                  <br />
                  <p
                    style={{
                      marginTop: "7px",
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                    }}
                    className="pcs-notes"
                  >
                    {!isNull(invoice) && invoice.note}
                  </p>
                </div>

                <div
                  style={{ clear: "both", marginTop: "30px", width: "100%" }}
                >
                  <label
                    style={{ fontSize: "10pt" }}
                    id="tmp_terms_label"
                    className="pcs-label"
                  >
                    {!isNull(invoice) &&
                      (LanguageEnum[invoice.customer.language] ===
                        LanguageEnum.ENG
                        ? "Terms & Conditions "
                        : "Uvjeti i odredbe ")}
                  </label>
                  <br />
                  <p
                    style={{
                      marginTop: "7px",
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                    }}
                    className="pcs-terms"
                  >
                    {!isNull(invoice) && invoice.termsAndConditions}
                  </p>
                </div>

                <div style={{ pageBreakInside: "avoid" }}></div>
              </div>
            </div>

            <div className="pcs-template-footer">
              <div className="pcs-footer-content">
                <div style={{ textAlign: "center" }}>
                  <font size="1">
                    Portobyte d.o.o. za računalne djelatnosti∣ Garešnička 24 ∣
                    10040, Zagreb ∣ OIB 3374060098∣
                    HR2324020061101074647&nbsp;Erste banka d.d. Zagreb ∣
                    Temeljni kapital 20.000,00 kn (uplaćen u cijelosti) ∣&nbsp;
                  </font>
                  <span style={{ fontSize: "x-small" }}>
                    Trgovački sud Zagreb reg. broj Tt-22/5411-2∣ Direktor:
                    Kristijan Zdelarec
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoicePreview;
