export const SendPostRequest = async (url, requestBody, token, callback) => {
    let data;
    let error;
    let loading = true;
    try {
        const response = await fetch(url, {
          method: "POST",
          body: JSON.stringify(requestBody),
          headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Authorization": token,
            },
        });
        if (!response.ok) {
          error = {
            showError: true,
            errorCode: response.status,
            errorMessage: response.statusText,
          };
          throw new Error(response.statusText)
        }
  
        const parsedData = await response.json();

        data = parsedData;
        loading = false;
      } catch (error) {
        if(error.showError) {
          error = {
            showError: true,
            errorMessage: error.message
          };
        }
      }
      if(typeof(callback) === "function") {
        callback(data);
      }
    return {data, error, loading};
}

export const SendPutRequest = async (url, requestBody, token, callback) => {
  let data;
  let error;
  let loading = true;
  try {
      const response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(requestBody),
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": token,
          },
      });
      if (!response.ok) {
        error = {
          showError: true,
          errorCode: response.status,
          errorMessage: response.statusText,
        };
        throw new Error(response.statusText)
      }

      const parsedData = await response.json();

      data = parsedData;
      loading = false;
    } catch (error) {
      if(error.showError) {
        error = {
          showError: true,
          errorMessage: error.message
        };
      }
    }
    if(typeof(callback) === "function") {
      callback(data);
    }
  return {data, error, loading};
}

export const SendDeleteRequest = async (url, token, callback) => {
  let data;
  let error;
  let loading = true;
  try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": token,
          },
      });
      if (!response.ok) {
        error = {
          showError: true,
          errorCode: response.status,
          errorMessage: response.statusText,
        };
        throw new Error(response.statusText)
      }

      const parsedData = await response.json();

      data = parsedData;
      loading = false;
    } catch (error) {
      if(error.showError) {
        error = {
          showError: true,
          errorMessage: error.message
        };
      }
    }
    if(typeof(callback) === "function") {
      callback(data);
    }
  return {data, error, loading};
}

export const SendGetRequest = async (url, token, callback) => {
  let data;
  let error;
  let loading = true;
  try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": token,
          },
      });
      if (!response.ok) {
        error = {
          showError: true,
          errorCode: response.status,
          errorMessage: response.statusText,
        };
        throw new Error(response.statusText)
      }

      const parsedData = await response.json();

      data = parsedData;
      loading = false;
    } catch (error) {
      if(error.showError) {
        error = {
          showError: true,
          errorMessage: error.message
        };
      }
    }
    callback(data);
  return {data, error, loading};
}

export const SendPostRequestAndDownload = async (url, requestBody, token) => {
  try {
      const response = await fetch(url, {
          method: "POST",
          body: JSON.stringify(requestBody),
          headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Authorization": token,
          },
      });

      if (!response.ok) {
          throw new Error(`Error ${response.status}: ${response.statusText}`);
      }

      // Get filename from response headers
      const contentDisposition = response.headers.get("Content-Disposition");
      let filename = "document.pdf"; // Default filename

      if (contentDisposition && contentDisposition.includes("filename=")) {
          const match = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
          if (match && match[1]) {
              filename = match[1].replace(/['"]/g, ""); // Remove quotes
          }
      }

      const blob = await response.blob();
      const urlObject = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = urlObject;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(urlObject);
  } catch (error) {
      console.error("Download failed:", error);
  }
};

export const GetPostRequestAndDownload = async (url, token) => {
  try {
      const response = await fetch(url, {
          method: "GET",
          headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Authorization": token,
          },
      });

      if (!response.ok) {
          throw new Error(`Error ${response.status}: ${response.statusText}`);
      }

      // Get filename from response headers
      const contentDisposition = response.headers.get("Content-Disposition");
      let filename = "document.pdf"; // Default filename

      if (contentDisposition && contentDisposition.includes("filename=")) {
          const match = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
          if (match && match[1]) {
              filename = match[1].replace(/['"]/g, ""); // Remove quotes
          }
      }

      const blob = await response.blob();
      const urlObject = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = urlObject;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(urlObject);
  } catch (error) {
      console.error("Download failed:", error);
  }
};