import {
  Button,
  TextField,
  FormControl,
  Divider,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Paper,
  Toolbar,
  Autocomplete,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useApiRequest from "../../store/use-http";
import ConditionsEnum from "../../enum/ConditionsEnum";
import PaymentMethodEnum from "../../enum/PaymentMethodEnum";
import AuthContext from "../../store/auth-context";
import { useNavigate } from "react-router-dom";
import { isNull } from "../../utils/Utils";
import InvoiceItems from "./InvoiceItems";
import { baseUrl } from "../../Config";
import "./InvoicePage.css";
import LanguageEnum from "../../enum/LanguageEnum";

const formStyle = {
  boxShadow: "0px 13px 20px 0px #80808029",
  display: "flex",
  flexDirection: "column",
};

const FormContent = (props) => {
  const conditions = Object.keys(ConditionsEnum);
  const paymentMethods = Object.keys(PaymentMethodEnum);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const companyInfo = JSON.parse(sessionStorage.getItem("companyInfo"));
  const customers = props.customers;
  const items = props.items;
  const subscriptions = props.subscriptions;
  const projects = props.projects;

  const navigate = useNavigate();

  const [invoice, setInvoice] = useState(props.invoice);
  const [customer, setCustomer] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [project, setProject] = useState(null);
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [condition, setConditions] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [invoicingTime, setInvoicingTime] = useState("09:00");
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [calculations, setCalculations] = useState();
  const [notes, setNotes] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState(
    ""
  );

  useEffect(() => {
    if (!isNull(props.invoice)) {
      setInvoice(props.invoice);
      setInvoiceNumber(props.invoice.invoiceNumber);
      setInvoiceDate(props.invoice.invoiceDate);
      setDueDate(props.invoice.dueDate);
      setDeliveryDate(props.invoice.deliveryDate);
      setPaymentMethod(props.invoice.paymentMethod);
      setCustomer(props.invoice.customer);
      setProject(props.invoice.project);
      setConditions(props.invoice.conditions);
      setInvoiceItems(props.invoice.invoiceItems);
      setNotes(props.invoice.note);
      setTermsAndConditions(props.invoice.termsAndConditions);
      setSubscription(subscriptions.find((c) => c.id === props.invoice.subscriptionId));
    }
  }, [props.invoice, subscriptions]);

  const authContext = useContext(AuthContext);

  const handleItemAdded = (items) => {
    setInvoiceItems(items);
  };

  const handleCaluclationsChange = (calculations) => {
    setCalculations(calculations);
  };

  const handleClientSelect = (event, value) => {
    const client = value;
    setCustomer(client);
    if (LanguageEnum[client.language] === LanguageEnum.ENG) {
      setNotes(companyInfo.notesEng);
      setTermsAndConditions(companyInfo.termsAndConditionsEng);
    } else {
      setNotes(companyInfo.defaultNotes);
      setTermsAndConditions(companyInfo.defaultTermsAndConditions);
    }
  };

  const handleProjectSelect = (event, value) => {
    setProject(value);
  };

  const handleSubscriptionSelect = (event) => {
    const subscription = subscriptions.find((c) => c.id === event.target.value);
    setSubscription(subscription);
  };

  const hadleOnSaveClick = () => {
    const newInvoice = {
      id: props.invoice.id,
      customer: customer,
      invoiceNumber: invoiceNumber,
      invoiceDate: invoiceDate,
      conditions: condition,
      dueDate: dueDate,
      seller: user.firstName + " " + user.lastName,
      invoicingTime: invoicingTime,
      deliveryDate: deliveryDate,
      paymentMethod: paymentMethod,
      currency: customer.currency,
      invoiceItems: invoiceItems,
      subtotal: calculations.subtotal,
      subtotalLocalCurrency: calculations.subtotalLcy,
      subtotalHrk: calculations.subtotalHrk,
      totalTax: calculations.tax,
      totalTaxLocalCurrency: calculations.taxLcy,
      totalTaxHrk: calculations.taxHrk,
      total: calculations.subtotal + calculations.tax,
      totalLocalCurrency: calculations.subtotalLcy + calculations.taxLcy,
      totalHrk: calculations.subtotalHrk + calculations.taxHrk,
      note: notes,
      termsAndConditions: termsAndConditions,
      status: invoice.status,
      createdOn: props.invoice.createdOn,
      updatedOn: props.invoice.updatedOn,
      subscriptionId: isNull(subscription) ? null : subscription.id,
      project: project
    };
    if (isNull(newInvoice.id)) {
      saveHandler(newInvoice, "POST");
    } else {
      saveHandler(newInvoice, "PUT");
    }
  };

  const saveHandler = async (invoice, method) => {
    const url = baseUrl + "/api/invoice";
    fetch(url, {
      method: method,
      body: JSON.stringify(invoice),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authContext.token,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((data) => {
            let alert = "Failed to save the invoice";
            throw new Error(alert);
          });
        }
      })
      .then((data) => {
        navigate("/invoices/preview/" + data.id);
      })
      .catch((err) => console.log(err.message));
  };

  return (
    <>
      <TableContainer component={Paper} style={formStyle}>
        <Toolbar>
          <Button variant="contained" onClick={hadleOnSaveClick}>
            Save
          </Button>
        </Toolbar>

        <div>
          <FormControl sx={{ m: 1, minWidth: 500 }}>
            <Autocomplete
              disabled={props.isReadOnly}
              disablePortal
              options={customers}
              getOptionLabel={(option) => option.companyDisplayName}
              onChange={handleClientSelect}
              value={customer || null}
              renderInput={(params) => <TextField {...params} label="Customer" variant="outlined" />}
            />
          </FormControl>
          {customer && (
            <div className="info">
              <p>Payment Address:</p>
              <p>{customer.address}</p>
              <p>{customer.city}</p>
              <p>{customer.zipCode}</p>
              <p>{customer.country}</p>
            </div>
          )}
        </div>

        <div>
          <FormControl sx={{ m: 1, minWidth: 500 }}>
            <InputLabel id="demo-simple-select-label">Subscription</InputLabel>
            <Select
              disabled={props.isReadOnly}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Language"
              onChange={handleSubscriptionSelect}
              value={isNull(subscription) ? "" : subscription.id}
            >
              {
                (subscriptions && customer) &&
                subscriptions.filter((s) => s.customer.id == customer.id).map((data) => {
                  return (
                    <MenuItem value={data.id}>
                      {data.item.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          {subscription && (
            <div className="info">
              <p>Contract number: <b>{subscription.contractNumber}</b></p>
              <p>Payment option: {subscription.paymentType}</p>
            </div>
          )}
        </div>

        <div>
        <FormControl sx={{ m: 1, minWidth: 500 }}>
            <Autocomplete
              disabled={props.isReadOnly}
              disablePortal
              options={(projects && customer) &&
                projects.filter((p) => p.customer.id == customer.id)}
              getOptionLabel={(option) => option.name}
              onChange={handleProjectSelect}
              value={project || null}
              renderInput={(params) => <TextField {...params} label="Project" variant="outlined" />}
            />
          </FormControl>
        </div>

        <div>
          <Divider sx={{ m: 1 }} />
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="full-width-text-field"
            label="Invoice Number"
            value={invoiceNumber}
            onChange={(e) => setInvoiceNumber(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="outlined-required"
            label="Invoice Date"
            defaultValue="1-1-1"
            type="date"
            value={invoiceDate || ""}
            onChange={(e) => setInvoiceDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl sx={{ m: 1, minWidth: 250 }}>
            <InputLabel
              id="demo-simple-select-label"
              shrink={true}
              style={{
                background: "white",
                paddingLeft: "4px",
                paddingRight: "4px",
              }}
            >
              Conditions
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Payment Method"
              onChange={(event) => setConditions(event.target.value)}
              defaultValue={condition}
              value={condition}
            >
              {conditions.map((key) => {
                return <MenuItem value={key}>{ConditionsEnum[key]}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="date"
            label="Due Date"
            type="date"
            value={dueDate}
            onChange={(e) => setDueDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div>
          <Divider sx={{ m: 1 }} />
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="full-width-text-field"
            label="Seller"
            value={user.firstName + " " + user.lastName}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="outlined-required"
            label="Delivery Date"
            type="date"
            value={deliveryDate}
            onChange={(e) => setDeliveryDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl sx={{ m: 1, minWidth: 250 }}>
            <InputLabel
              id="demo-simple-select-label"
              shrink={true}
              style={{
                background: "white",
                paddingLeft: "4px",
                paddingRight: "4px",
              }}
            >
              Payment Method
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Payment Method"
              onChange={(event) => setPaymentMethod(event.target.value)}
              defaultValue={paymentMethod}
              value={paymentMethod}
            >
              {paymentMethods.map((key) => {
                return (
                  <MenuItem value={key}>{PaymentMethodEnum[key]}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="time"
            label="Invoicing Time"
            type="time"
            value={invoicingTime}
            onChange={(e) => setInvoicingTime(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Divider sx={{ m: 1 }} />
        </div>
        <div>
          <InvoiceItems
            items={items}
            invoiceItems={invoiceItems}
            handleItemAdded={handleItemAdded}
            handleCaluclationsChange={handleCaluclationsChange}
          />
        </div>
        <Paper
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            paddingBottom: "16px",
          }}
        >
          <TextField
            label="Notes"
            style={{ width: "40%" }}
            placeholder="MultiLine with rows: 2 and rowsMax: 4"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            multiline
            rows={5}
            maxRows={10}
          />
          <TextField
            label="Terms and Conditions"
            style={{ width: "40%" }}
            placeholder="MultiLine with rows: 2 and rowsMax: 4"
            value={termsAndConditions}
            onChange={(e) => setTermsAndConditions(e.target.value)}
            multiline
            rows={5}
            maxRows={10}
          />
        </Paper>
      </TableContainer>
    </>
  );
};

const InvoiceForm = (props) => {
  const authContext = useContext(AuthContext);
  const params = useParams();

  const [customers, setCustomers] = useState([]);
  const [items, setItems] = useState([]);
  const [invoice, setInvoice] = useState();
  const [subscriptions, setSubscriptions] = useState([]);
  const [projects, setProjects] = useState([]);

  const customersUrl = baseUrl + "/api/customer/all";
  const subscriptionsUrl = baseUrl + "/api/subscription/all/active";
  const projectsUrl = baseUrl + "/api/project/all";

  const allCustomers = useApiRequest(customersUrl, "GET", authContext.token);
  const allSubscriptions = useApiRequest(subscriptionsUrl, "GET", authContext.token);
  const allProjects = useApiRequest(projectsUrl, "GET", authContext.token);

  useEffect(() => {
    setCustomers(allCustomers.data);
  }, [allCustomers]);

  useEffect(() => {
    setSubscriptions(allSubscriptions.data);
  }, [allSubscriptions]);

  useEffect(() => {
    setProjects(allProjects.data);
  }, [allProjects]);

  const initInvoiceUrl = isNull(params.invoiceId)
    ? baseUrl + "/api/invoice/init"
    : baseUrl + "/api/invoice?id=" + params.invoiceId;

  const initInvoice = useApiRequest(initInvoiceUrl, "GET", authContext.token);

  useEffect(() => {
    setInvoice(initInvoice.data);
  }, [initInvoice]);

  const itemsUrl = baseUrl + "/api/item/all";

  const itemsResult = useApiRequest(itemsUrl, "GET", authContext.token);

  useEffect(() => {
    setItems(itemsResult.data);
  }, [itemsResult]);

  return (
    <div>
      <FormContent invoice={invoice} customers={customers} items={items} subscriptions={subscriptions} projects={projects}/>
    </div>
  );
};

export default InvoiceForm;
