import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import {
  Box,
  Chip,
  IconButton,
  TablePagination,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  UilPlus,
  UilInfoCircle,
  UilPen,
  UilTrashAlt,
  UilMoneyBillStack,
  UilFileCheck,
} from "@iconscout/react-unicons";
import { useNavigate } from "react-router-dom";
import { SendDeleteRequest, SendPutRequest } from "../../actions/Actions";
import { useContext } from "react";
import AuthContext from "../../store/auth-context";
import BackdropContext from "../../store/backdrop-context";
import { formatDate } from "../../utils/Utils";
import InvoiceStatusEnum from "../../enum/InvoiceStatusEnum";
import { baseUrl } from "../../Config";
import PropTypes from "prop-types";
import { visuallyHidden } from "@mui/utils";
import Color from "../../enum/Color";
import CreateButton from "../Common/CreateButton";

const statusStyle = (status) => {
  if (status === InvoiceStatusEnum.PAID) {
    return {
      backgroundColor: Color.SUCCESS,
      color: Color.FOREGROUND,
    };
  } else if (status === InvoiceStatusEnum.SENT) {
    return {
      backgroundColor: Color.WARNING,
      color: Color.FOREGROUND,
    };
  } else {
    return {
      backgroundColor: Color.BACKGROUND,
      color: Color.FOREGROUND,
    };
  }
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "invoiceDate",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "invoiceNumber",
    numeric: false,
    disablePadding: false,
    label: "Invoice Number",
  },
  {
    id: "dueDate",
    numeric: false,
    disablePadding: false,
    label: "Due Date",
  },
  {
    id: "clientName",
    numeric: false,
    disablePadding: false,
    label: "Client name",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "total",
    numeric: false,
    disablePadding: false,
    label: "Total (EUR)",
  },
  {
    id: "totalHrk",
    numeric: false,
    disablePadding: false,
    label: "Total (HRK)",
  },
  {
    id: "primaryActions",
    numeric: false,
    disablePadding: false,
    label: "",
  },
  {
    id: "secondaryActions",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = () => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Invocies
      </Typography>
    </Toolbar>
  );
};

const InvoicesTable = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [isReadOnly, setIsReadOnly] = useState(false);
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const backdropContext = useContext(BackdropContext);

  const tableData = props.data;

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const showDetails = (rowData, isReadOnly) => {
    navigate("/invoices/preview/" + rowData.id);
  };

  const sendHandler = (rowData) => {
    const url = baseUrl + "/api/invoice";
    rowData.status = InvoiceStatusEnum.SENT;
    SendPutRequest(url, rowData, authContext.token, invoicesHandler);
    backdropContext.toggle();
  };

  const paidHandler = (rowData) => {
    const url = baseUrl + "/api/invoice";
    rowData.status = InvoiceStatusEnum.PAID;
    SendPutRequest(url, rowData, authContext.token, invoicesHandler);
    backdropContext.toggle();
  };

  const deleteHandler = (rowData, isReadOnly) => {
    const url = baseUrl + "/api/invoice?id=" + rowData.id;
    SendDeleteRequest(url, authContext.token, invoicesHandler);
    backdropContext.toggle();
  };

  const invoicesHandler = () => {
    backdropContext.toggle();
    props.refreshHandler();
  }

  const editHandelr = (rowData) => {
    navigate("/invoices/form/" + rowData.id);
  };

  const openForm = (rowData) => {
    navigate("/invoices/form/");
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

  return (
    <div className="Table">
      <CreateButton action={openForm} icon={<UilPlus />} text="New Invoice" />

      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }} elevation={5}>
          <EnhancedTableToolbar />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={tableData.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(tableData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell align={"left"} component="th" scope="row">
                          {formatDate(row.invoiceDate)}
                        </TableCell>
                        <TableCell align={"left"}>
                          {row.invoiceNumber}
                        </TableCell>
                        <TableCell align={"left"} component="th" scope="row">
                          {formatDate(row.dueDate)}
                        </TableCell>
                        <TableCell align={"left"}>
                          {row.customer.companyDisplayName}
                        </TableCell>
                        <TableCell align={"right"}>
                          <Chip
                            sx={{ width: "80px" }}
                            label={row.status}
                            style={statusStyle(row.status)}
                          />
                        </TableCell>
                        <TableCell align={"left"}>
                          {row.total + " " + row.currency}
                        </TableCell>
                        <TableCell align={"left"}>{row.totalHrk} HRK</TableCell>
                        <TableCell align={"left"} className="Details">
                          <Tooltip title="Show invoice">
                            <IconButton onClick={() => showDetails(row, true)}>
                              <UilInfoCircle
                                style={{ color: Color.BACKGROUND }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit invoice">
                            <IconButton
                              onClick={() => editHandelr(row)}
                              disabled={row.status === InvoiceStatusEnum.PAID}
                            >
                              <UilPen
                                style={{
                                  color:
                                    row.status === InvoiceStatusEnum.PAID
                                      ? Color.DISABLED
                                      : Color.BACKGROUND,
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Mark as sent">
                            <IconButton
                              onClick={() => sendHandler(row)}
                              disabled={row.status === InvoiceStatusEnum.PAID}
                            >
                              <UilFileCheck
                                style={{
                                  color:
                                    row.status !== InvoiceStatusEnum.DRAFT
                                      ? Color.DISABLED
                                      : Color.BACKGROUND,
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Mark as paid">
                            <IconButton
                              onClick={() => paidHandler(row)}
                              disabled={
                                row.status === InvoiceStatusEnum.PAID ||
                                row.status === InvoiceStatusEnum.DRAFT
                              }
                            >
                              <UilMoneyBillStack
                                style={{
                                  color:
                                    row.status === InvoiceStatusEnum.PAID ||
                                    row.status === InvoiceStatusEnum.DRAFT
                                      ? Color.DISABLED
                                      : Color.SUCCESS,
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell align={"left"} className="Details">
                          <Tooltip title="Delete invoice">
                            <IconButton
                              disabled={row.status === InvoiceStatusEnum.PAID}
                              onClick={() => deleteHandler(row, false)}
                            >
                              <UilTrashAlt
                                style={{
                                  color:
                                    row.status === InvoiceStatusEnum.PAID
                                      ? Color.DISABLED
                                      : Color.DANGER,
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </div>
  );
};

export default InvoicesTable;
